import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <g transform="translate(8, 28)">
        <path
          d="M30.8636 15.4545C30.8636 18.589 30.2765 21.2737 29.1023 23.5085C27.9375 25.7339 26.3466 27.4384 24.3295 28.6222C22.322 29.8059 20.0445 30.3977 17.4972 30.3977C14.9498 30.3977 12.6676 29.8059 10.6506 28.6222C8.64299 27.429 7.05208 25.7197 5.87784 23.4943C4.71307 21.2595 4.13068 18.5795 4.13068 15.4545C4.13068 12.3201 4.71307 9.64015 5.87784 7.41477C7.05208 5.17992 8.64299 3.47064 10.6506 2.28693C12.6676 1.10322 14.9498 0.511363 17.4972 0.511363C20.0445 0.511363 22.322 1.10322 24.3295 2.28693C26.3466 3.47064 27.9375 5.17992 29.1023 7.41477C30.2765 9.64015 30.8636 12.3201 30.8636 15.4545ZM25.5653 15.4545C25.5653 13.2481 25.2197 11.3873 24.5284 9.87216C23.8466 8.34754 22.8996 7.19697 21.6875 6.42045C20.4754 5.63447 19.0786 5.24148 17.4972 5.24148C15.9157 5.24148 14.5189 5.63447 13.3068 6.42045C12.0947 7.19697 11.143 8.34754 10.4517 9.87216C9.76989 11.3873 9.42898 13.2481 9.42898 15.4545C9.42898 17.661 9.76989 19.5265 10.4517 21.0511C11.143 22.5663 12.0947 23.7169 13.3068 24.5028C14.5189 25.2794 15.9157 25.6676 17.4972 25.6676C19.0786 25.6676 20.4754 25.2794 21.6875 24.5028C22.8996 23.7169 23.8466 22.5663 24.5284 21.0511C25.2197 19.5265 25.5653 17.661 25.5653 15.4545ZM38.0192 30H32.3942L42.6357 0.90909H49.1413L59.397 30H53.772L46.0021 6.875H45.7749L38.0192 30Z"
          fill="currentColor"
        />
        </g>
      </g>
    </g>
  </svg>
);

export default IconLogo;
